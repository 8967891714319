import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "./Bio.scss";

export default function Bio() {
  const author = useStaticQuery(graphql`
    {
      contentfulProfile {
        nickname
        photo {
          gatsbyImageData(layout: CONSTRAINED, width: 180, aspectRatio: 1)
        }
      }
    }
  `);
  const {
    contentfulProfile: { photo },
  } = author;
  return (
    <section className="bio-section">
      <div className="profile">
        <div className="profile-image">
          <GatsbyImage image={getImage(photo)} alt="homiedev" />
        </div>

        <div className="summary">
          <h1 className="title">
            <span role="img" aria-label="fire">
              🔥
            </span>{" "}
            Devnav Creator Bio
          </h1>
          <p>
            <span role="img" aria-label="smile">
              😁😁
            </span>
            Hi, I'm @devnav. Một người thích chia sẻ kiến thức, đặc biệt là về
            Front-end 🚀.
          </p>
          <div className="social-box">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/c/Homiedev"
            >
              Youtube
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/himdevnav/"
            >
              Facebook
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/brthrain/"
            >
              Instagram
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://github.com/devnav2902"
            >
              Github
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
