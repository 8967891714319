import React from "react";
import About from "component/About/About";
import Layout from "component/Layout/Layout";
import Seo from "component/seo";
import SidebarLeft from "component/Sidebar/SidebarLeft";
import SidebarRight from "component/Sidebar/SidebarRight";
import { useSiteUrl } from "utils";

export default function AboutPage({ location }) {
  const canonicalUrl = `${useSiteUrl()}${location.pathname}`;

  return (
    <Layout>
      <Seo titleSample={true} title="About" canonicalUrl={canonicalUrl} />
      <SidebarLeft />
      <About />
      <SidebarRight />
    </Layout>
  );
}
